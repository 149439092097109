import { AppContainer } from '../styles/Global.styles';
import { Section } from '../styles/Section.styles';


const About = () => {
    return (
        <AppContainer id ="projects">
            <Section heading='PROJECTS: Coming soon!'>
            </Section>
        </AppContainer>
    );
};

export default About;